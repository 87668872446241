import { createAction } from "../../utils/reducer/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";

export const setUser = (user) => createAction(USER_ACTION_TYPES.SET_USER, user);

export const setSearchedAccounts = (searchedAccounts) =>
    createAction(USER_ACTION_TYPES.SET_SEARCHED_ACCOUNTS, searchedAccounts);

export const fetchUserStart = () =>
    createAction(USER_ACTION_TYPES.FETCH_USER_START);

export const fetchUserSuccess = (user) =>
    createAction(USER_ACTION_TYPES.FETCH_USER_SUCCESS, user);

export const fetchUserFailed = (error) =>
    createAction(USER_ACTION_TYPES.FETCH_USER_FAILED, error);

export const fetchSearchedUserStart = (id) =>
    createAction(USER_ACTION_TYPES.FETCH_SEARCHED_USER, id);

export const fetchSearchedUserSuccess = (searchedUser) =>
    createAction(USER_ACTION_TYPES.FETCH_SEARCHED_USER_SUCCESS, searchedUser);

export const fetchSearchedUserFailed = (error) =>
    createAction(USER_ACTION_TYPES.FETCH_SEARCHED_USER_FAILED, error);
