import React from "react";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/main-logo.jpeg";
import './portalTopBar.styles.scss'

const PortalTopBar = () => (
    <div className="portal-top-bar-container">
        <div className="portal-top-bar-logo-container">
                <Link className='portal-top-bar-logo-element' to='/portal'>
                    <img className="portal-top-bar-logo" src={mainLogo} alt="fireSpot" />
                </Link>
                <hr className="portal-top-bar-hr" />
                <div className="portal-top-bar-title-container">
                    <span className="portal-top-bar-title">ORMA</span>
                    <span className="portal-top-bar-subtitle">Servicios</span>
                </div>
            </div>
    </div>
);

export default PortalTopBar;
