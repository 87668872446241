import { Route, Routes } from "react-router-dom";
import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import About from "./routes/about/about.component";
import Portal from "./routes/portal/portal.component";
import Profile from "./routes/profile/profile";
import PortalNavigation from "./routes/portalNavigation/portalNavigation.component";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserStart } from "./store/user/user.actions";
import PasswordRecovery from "./routes/passwordRecovery/passwordRecovery.component";

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserStart())
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="auth/login" element={<PasswordRecovery />} />
      </Route>
      <Route path="/portal" element={<PortalNavigation />}>
        <Route index element={<Portal />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default App;
