import React, { useState } from 'react'
import FormInput from '../form-input/form-input.component';
import './loginForm.styles.scss'
import Button from '../button/button.component';
import { login } from '../../utils/api/api';
import { useDispatch } from "react-redux";
import { setUser } from '../../store/user/user.actions';
import { setIsOpen, setModalType } from '../../store/modal/modal.action';

const defaultFormFields = {
    email: "",
    password: "",
};

const LoginForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password } = formFields;
    const dispatch = useDispatch()
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const res = await login(formFields)
        if (res.error) {
            alert('Usuario o Contraseña Incorrectos')
        } else {
            const { accessToken, account } = res.data;
            localStorage.setItem('token', accessToken)
            dispatch(setIsOpen(false))
            dispatch(setUser(account))
        }
    }
    const hadleForgot = () => {
        dispatch(setModalType('forgot'))
    }
    return (
        <div className="login-form-container">
            <div className="login-form-title-container">
                <span className="login-form-title">Iniciar Sesión</span>
            </div>
            <form className="login-form-first" onSubmit={handleSubmit}>
                <div className='login-form-input-container'>
                    <FormInput
                        label="Correo Electronico"
                        type="text"
                        registerForm
                        required
                        onChange={handleChange}
                        name="email"
                        value={email}
                    />
                    <FormInput
                        label="Contraseña"
                        type="password"
                        registerForm
                        required
                        onChange={handleChange}
                        name="password"
                        value={password}
                    />
                </div>
                <p className='forgot-password' onClick={hadleForgot}>¿Olvidaste la contraseña?</p>
                <Button type="submit">Iniciar Sesión</Button>
            </form>
        </div>
    )
}

export default LoginForm