import React, { useEffect, useState } from "react";
import "./navigation.styles.scss";
import TopBar from "../../components/topBar/topBar.component";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import SmallTopBar from "../../components/smallTopBar/smallTopBar.component";
import Modal from "../../components/modal/modal.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/user.selector";
import { activateAccount } from "../../utils/api/api";

const Navigation = () => {
    const [is900, setIs900] = useState(false);
    const navigate = useNavigate();
    const { user } = useSelector(selectUser);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailParam = params.get("email");
        const tokenParam = params.get("token");
        if (emailParam) setEmail(emailParam);
        if (tokenParam) setToken(tokenParam);
        if(email && token){
            activateAccount(email, token);
        }
        if (user.id) {
            navigate("/portal");
        }
        if (window.innerWidth < 950) {
            setIs900(true);
        } else {
            setIs900(false);
        }
        const updateBars = () => {
            if (window.innerWidth < 950) {
                setIs900(true);
            } else {
                setIs900(false);
            }
        };
        window.addEventListener("resize", updateBars);

        return () => {
            window.removeEventListener("resize", updateBars);
        };
    }, [user, email, token]);
    return (
        <div className="whole-navigation-container" id="whole-navigation-container">
            <div className="navigation-container">
                {is900 ? <SmallTopBar /> : <TopBar />}
            </div>
            <Outlet />

            <Modal />
            <div className="navigation-footer-container">
                <Footer />
            </div>
        </div>
    );
};

export default Navigation;
