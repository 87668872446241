import React from "react";
import "./portal.styles.scss";
import HomePortal from "../../components/homePortal/homePortal.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/user.selector";
import AdminPortal from "../../components/adminPortal/adminPortal.component";
import Modal from "../../components/modal/modal.component";
const Portal = () => {
    const { user } = useSelector(selectUser);
    return (
        <div className="portal-route-container" id="portal-route-container">
            {user.accountType === 'Admins' ? <AdminPortal /> : <HomePortal />}
            <Modal />
        </div>
    );
};

export default Portal;
