import React, { useState } from "react";
import OrmaInput from "../../components/orma-input/ormaInput.component";
import Button from "../../components/button/button.component";
import "./profile.styles.scss";
import {
    defaultPasswordFormFields,
    defaultValidators,
    formatDate,
    validateDate,
    validatePassword,
} from "../../utils/helpers/passwordValidator";
import { useDispatch } from "react-redux";
import { selectUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import { changePassword, updateUserInfo } from "../../utils/api/api";
import { setUser } from "../../store/user/user.actions";
import PasswordValidator from "../../components/passwordValidator/passwordValidator.component";

const Profile = () => {
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const modifiedUser = { ...user, dateOfBirth: validateDate(user.dateOfBirth) };
    const [formFields, setFormFields] = useState(modifiedUser);
    const [errors, setErrors] = useState(defaultValidators);
    const [passwordFormFields, setPasswordFormFields] = useState(
        defaultPasswordFormFields
    );
    const [editInfo, setEditInfo] = useState(true);
    const {
        firstName,
        lastName,
        secondLastName,
        dateOfBirth,
        placeOfBirth,
        phone,
        street,
        extNumber,
        insideNumber,
        hood,
        city,
        state,
        pc,
        parentFullName,
    } = formFields;
    const { password, newPassword, reEnteredPassword } = passwordFormFields;
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        if (name === "newPassword") {
            setPasswordFormFields({ ...passwordFormFields, [name]: value });
            validatePassword(value, setErrors);
        } else {
            setPasswordFormFields({ ...passwordFormFields, [name]: value });
        }
    };
    const handleClick = () => {
        setEditInfo(!editInfo);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== reEnteredPassword) {
            alert("Las contraseñas deben coincidir");
            return
        }
        if (errors.min && errors.num && errors.capital) {
            const response = await changePassword(password, newPassword, reEnteredPassword);
            if (response.error) {
                alert('Contraseña Incorrecta')
                return
            } else {
                alert('Contraseña actualizada con éxito')
                setEditInfo(!editInfo);
            }
        } else {
            alert("La contraseña debe cumplir con los valores");
        }
    };
    const handleProfileSubmit = async (event) => {
        event.preventDefault();
        const { statusCode, data } = await updateUserInfo(formFields);
        if (statusCode === 200) {
            dispatch(setUser(data));
            alert("Información Actualizada");
        }
    };
    const handleCancel = () => {
        setPasswordFormFields(defaultPasswordFormFields);
        setFormFields(modifiedUser);
        setEditInfo(true);
    };
    return (
        <div className="portal-profile-container">
            <header className="profile-header-portal">
                <div className="profile-welcome">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDEuMjVhNC43NSA0Ljc1IDAgMSAwIDAgOS41YTQuNzUgNC43NSAwIDAgMCAwLTkuNU04Ljc1IDZhMy4yNSAzLjI1IDAgMSAxIDYuNSAwYTMuMjUgMy4yNSAwIDAgMS02LjUgME0xMiAxMi4yNWMtMi4zMTMgMC00LjQ0NS41MjYtNi4wMjQgMS40MTRDNC40MiAxNC41NCAzLjI1IDE1Ljg2NiAzLjI1IDE3LjV2LjEwMmMtLjAwMSAxLjE2Mi0uMDAyIDIuNjIgMS4yNzcgMy42NjJjLjYyOS41MTIgMS41MS44NzcgMi43IDEuMTE3YzEuMTkyLjI0MiAyLjc0Ny4zNjkgNC43NzMuMzY5czMuNTgtLjEyNyA0Ljc3NC0uMzY5YzEuMTktLjI0IDIuMDctLjYwNSAyLjctMS4xMTdjMS4yNzktMS4wNDIgMS4yNzctMi41IDEuMjc2LTMuNjYyVjE3LjVjMC0xLjYzNC0xLjE3LTIuOTYtMi43MjUtMy44MzZjLTEuNTgtLjg4OC0zLjcxMS0xLjQxNC02LjAyNS0xLjQxNE00Ljc1IDE3LjVjMC0uODUxLjYyMi0xLjc3NSAxLjk2MS0yLjUyOGMxLjMxNi0uNzQgMy4xODQtMS4yMjIgNS4yOS0xLjIyMmMyLjEwNCAwIDMuOTcyLjQ4MiA1LjI4OCAxLjIyMmMxLjM0Ljc1MyAxLjk2MSAxLjY3NyAxLjk2MSAyLjUyOGMwIDEuMzA4LS4wNCAyLjA0NC0uNzI0IDIuNmMtLjM3LjMwMi0uOTkuNTk3LTIuMDUuODExYy0xLjA1Ny4yMTQtMi41MDIuMzM5LTQuNDc2LjMzOWMtMS45NzQgMC0zLjQyLS4xMjUtNC40NzYtLjMzOWMtMS4wNi0uMjE0LTEuNjgtLjUwOS0yLjA1LS44MWMtLjY4NC0uNTU3LS43MjQtMS4yOTMtLjcyNC0yLjYwMSIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+"
                        alt="icon-user"
                    />
                    <p className="profile-header">Mi perfil</p>
                </div>
                <div className="profile-date">
                    <p>
                        <span>Miembro desde:</span> {formatDate(user.createdAt)}
                    </p>
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Im0xMC42IDEzLjhsLTIuMTUtMi4xNXEtLjI3NS0uMjc1LS43LS4yNzV0LS43LjI3NXEtLjI3NS4yNzUtLjI3NS43dC4yNzUuN0w5LjkgMTUuOXEuMy4zLjcuM3QuNy0uM2w1LjY1LTUuNjVxLjI3NS0uMjc1LjI3NS0uN3QtLjI3NS0uN3EtLjI3NS0uMjc1LS43LS4yNzV0LS43LjI3NXpNMTIgMjJxLTIuMDc1IDAtMy45LS43ODh0LTMuMTc1LTIuMTM3cS0xLjM1LTEuMzUtMi4xMzctMy4xNzVUMiAxMnEwLTIuMDc1Ljc4OC0zLjl0Mi4xMzctMy4xNzVxMS4zNS0xLjM1IDMuMTc1LTIuMTM3VDEyIDJxMi4wNzUgMCAzLjkuNzg4dDMuMTc1IDIuMTM3cTEuMzUgMS4zNSAyLjEzOCAzLjE3NVQyMiAxMnEwIDIuMDc1LS43ODggMy45dC0yLjEzNyAzLjE3NXEtMS4zNSAxLjM1LTMuMTc1IDIuMTM4VDEyIDIybTAtMnEzLjM1IDAgNS42NzUtMi4zMjVUMjAgMTJxMC0zLjM1LTIuMzI1LTUuNjc1VDEyIDRROC42NSA0IDYuMzI1IDYuMzI1VDQgMTJxMCAzLjM1IDIuMzI1IDUuNjc1VDEyIDIwbTAtOCIvPjwvc3ZnPg=="
                        alt="icon-check"
                    />
                </div>
            </header>
            <div className="profile-content-form">
                {editInfo ? (
                    <div className="container-profile-info">
                        <div className="profile-user">Nombre de Usuario:</div>
                        <div className="profile-info">
                            {user.firstName + " " + user.lastName}
                        </div>
                        <div className="profile-info">{user.email}</div>
                        <div className="profile-edit-button">
                            <Button onClick={handleClick}>Cambiar contraseña</Button>
                        </div>
                    </div>
                ) : (
                    <form className="profile-edit-info" onSubmit={handleSubmit}>
                        <OrmaInput
                            label="Contraseña actual"
                            type="password"
                            id="password"
                            required
                            onChange={handlePasswordChange}
                            name="password"
                            value={password}
                        />
                        <div className="profile-edit-form">
                            <OrmaInput
                                label="Contraseña Nueva"
                                type="password"
                                id="newPassword"
                                required
                                onChange={handlePasswordChange}
                                name="newPassword"
                                value={newPassword}
                            />
                            <OrmaInput
                                label="Confirmar Contraseña"
                                type="password"
                                id="reEnteredPassword"
                                required
                                onChange={handlePasswordChange}
                                name="reEnteredPassword"
                                value={reEnteredPassword}
                            />
                        </div>
                        <PasswordValidator errors={errors} />
                        <div className="profile-section-button">
                            <div className="profile-edit-button">
                                <Button type="button" onClick={handleCancel}>
                                    Cancelar
                                </Button>
                            </div>
                            <div className="profile-edit-button">
                                <Button type="submit">Cambiar contraseña</Button>
                            </div>
                        </div>
                    </form>
                )}
                <form onSubmit={handleProfileSubmit}>
                    <span className="profile-form-title">Información de la cuenta:</span>
                    <div className="profile-edit-form">
                        <OrmaInput
                            label="Nombre(s)"
                            type="text"
                            id="firstName"
                            required
                            onChange={handleChange}
                            name="firstName"
                            value={firstName}
                        />
                        <OrmaInput
                            label="Primer Apellido*"
                            type="text"
                            id="lastName"
                            required
                            onChange={handleChange}
                            name="lastName"
                            value={lastName}
                        />
                    </div>
                    <div className="profile-edit-form">
                        <OrmaInput
                            label="Segundo Apellido"
                            type="text"
                            id="secondLastName"
                            onChange={handleChange}
                            name="secondLastName"
                            value={secondLastName}
                        />
                        <OrmaInput
                            label="Fecha de Nacimiento*"
                            type="date"
                            id="dateOfBirth"
                            onChange={handleChange}
                            name="dateOfBirth"
                            value={dateOfBirth}
                        />
                    </div>
                    <div className="profile-edit-form">
                        <OrmaInput
                            label="Lugar de Nacimiento(Estado)"
                            type="text"
                            id="placeOfBirth"
                            onChange={handleChange}
                            name="placeOfBirth"
                            value={placeOfBirth}
                        />
                        <OrmaInput
                            label="#Celular"
                            type="text"
                            id="phone"
                            onChange={handleChange}
                            name="phone"
                            value={phone}
                        />
                    </div>

                    <span className="profile-form-title">Domicilio en México:</span>
                    <div className="profile-edit-form">
                        <OrmaInput
                            label="Calle"
                            type="text"
                            id="street"
                            onChange={handleChange}
                            name="street"
                            value={street}
                        />
                        <OrmaInput
                            label="#Exterior"
                            type="text"
                            id="extNumber"
                            onChange={handleChange}
                            name="extNumber"
                            value={extNumber}
                        />
                        <OrmaInput
                            label="#Interior"
                            type="text"
                            id="insideNumber"
                            onChange={handleChange}
                            name="insideNumber"
                            value={insideNumber}
                        />
                    </div>
                    <div className="profile-edit-form">
                        <OrmaInput
                            label="Colonia"
                            type="text"
                            id="hood"
                            onChange={handleChange}
                            name="hood"
                            value={hood}
                        />
                        <OrmaInput
                            label="Ciudad"
                            type="text"
                            id="city"
                            onChange={handleChange}
                            name="city"
                            value={city}
                        />
                    </div>
                    <div className="profile-edit-form">
                        <OrmaInput
                            label="Estado"
                            type="text"
                            id="state"
                            onChange={handleChange}
                            name="state"
                            value={state}
                        />
                        <OrmaInput
                            label="CP"
                            type="text"
                            id="pc"
                            onChange={handleChange}
                            name="pc"
                            value={pc}
                        />
                    </div>
                    <div className="profile-edit-form ">
                        <OrmaInput
                            label="Nombre completo de uno de los padres"
                            type="text"
                            id="parentFullName"
                            onChange={handleChange}
                            name="parentFullName"
                            value={parentFullName}
                        />
                    </div>
                    <div className="profile-section-button">
                        <div className="profile-button">
                            <Button type="button" onClick={handleCancel}>
                                Cancelar
                            </Button>{" "}
                        </div>
                        <div className="profile-button">
                            <Button type="submit">Guardar</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default Profile;
