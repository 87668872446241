import React from 'react'
import './textInformation.style.scss'

const TextInformation = ({ title, textInfo }) => {
    return (
      <div className='textInformation'>
        <h1>{title}</h1>
        <p>{textInfo}</p>
      </div>
    );
  };
export default TextInformation;