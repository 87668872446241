import React, { useState } from "react";
import "./registerForm.styles.scss";
import Button from "../button/button.component";
import OrmaInput from "../orma-input/ormaInput.component";
import { useDispatch } from 'react-redux';
import { defaultFormFields, defaultValidators, validatePassword } from "../../utils/helpers/passwordValidator";
import Select from "../select-input/select-input.component";
import { options } from "../../utils/helpers/selectorHelper";
import { registerUser } from "../../utils/api/api";
import { setIsOpen } from "../../store/modal/modal.action";
import PasswordValidator from "../passwordValidator/passwordValidator.component";

const RegisterForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [errors, setErrors] = useState(defaultValidators);
    const dispatch = useDispatch();

    const {
        email,
        password,
        reEnteredPassword,
        name,
        lastName,
        secondLastName,
        dateOfBirth,
        placeOfBirth,
        phoneNumber,
        street,
        ext,
        int,
        hood,
        city,
        state,
        pc,
        parentName,
        terms,
        privacy,
    } = formFields;
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "password") {
            setFormFields({ ...formFields, [name]: value });
            validatePassword(value, setErrors);
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
    };
    const handleCheck = (event) => {
        const { name } = event.target;
        if (formFields[name] === true) {
            setFormFields({ ...formFields, [name]: false });
        } else {
            setFormFields({ ...formFields, [name]: true });
        }
    };
    const handleRegister = async (event) => {
        event.preventDefault();
        if (password !== reEnteredPassword) {
            alert("Las contraseñas deben coincidir");
            return
        }
        if (errors.min && errors.num && errors.capital) {
            const response = await registerUser(formFields);
            if (response.error) {
                alert('Error: ' + response.error)
            } else {
                dispatch(setIsOpen(false))
                alert('Ya eres parte de la familia ORMA, te enviaremos un correo para verificar que eres tu, es necesario validar el correo antes de ingresar')
            }
        } else {
            alert("La contraseña debe cumplir con los valores");
        }
    };
    return (
        <div className="register-form-container">
            <div className="register-form-title-container">
                <span className="register-form-title">Registro</span>
                <span className="register-form-text">
                    Estás a un paso de formar parte de la comunidad de Orma Servicios
                </span>
            </div>
            <form onSubmit={handleRegister} className="register-form-form-container">
                <div className="register-form-first">
                    <OrmaInput
                        label="Correo Electronico*"
                        type="email"
                        id="email"
                        required
                        onChange={handleChange}
                        name="email"
                        value={email}
                    />
                    <div className="register-form-two-el">
                        <div className="register-password-input">
                            <OrmaInput
                                label="Contraseña*"
                                type="password"
                                id="password"
                                required
                                onChange={handleChange}
                                name="password"
                                value={password}
                            />
                            <OrmaInput
                                label="Confirmar Contraseña*"
                                type="password"
                                id="reEnteredPassword"
                                required
                                onChange={handleChange}
                                name="reEnteredPassword"
                                value={reEnteredPassword}
                            />
                        </div>
                        <PasswordValidator errors={errors} />
                    </div>
                </div>
                <div className="register-form-personal-information">
                    <span className="register-form-title">Información Personal</span>
                    <div className="container-register-form-two-el">
                        <div className="register-form-two-el">
                            <OrmaInput
                                label="Nombre(s)*"
                                type="text"
                                id="name"
                                required
                                onChange={handleChange}
                                name="name"
                                value={name}
                            />
                            <OrmaInput
                                label="Primer Apellido*"
                                type="text"
                                id="lastName"
                                required
                                onChange={handleChange}
                                name="lastName"
                                value={lastName}
                            />


                        </div>
                        <div className="register-form-two-el">
                            <OrmaInput
                                label="Segundo Apellido*"
                                type="text"
                                id="secondLastName"
                                required
                                onChange={handleChange}
                                name="secondLastName"
                                value={secondLastName}
                            />

                            <OrmaInput
                                label="Fecha de nacimiento*"
                                type="date"
                                id="dateOfBirth"
                                required
                                onChange={handleChange}
                                name="dateOfBirth"
                                value={dateOfBirth}
                            />

                        </div>
                        <div className="register-form-two-el">
                            <Select
                                label="Lugar de Nacimiento*:"
                                id="placeOfBirth"
                                options={options}
                                name="placeOfBirth"
                                onChange={handleChange}
                                value={placeOfBirth}
                            />
                            <OrmaInput
                                label="Numero Telefonico*"
                                type="text"
                                id="phoneNumber"
                                required
                                onChange={handleChange}
                                name="phoneNumber"
                                value={phoneNumber}
                            />
                        </div>
                    </div>

                </div>
                <div className="register-form-address">
                    <span className="register-form-title">Domicilio en México</span>
                    <div className="container-register-form-two-el">
                        <div className="register-form-two-el">
                            <OrmaInput
                                label="Calle*"
                                type="text"
                                id="street"
                                required
                                onChange={handleChange}
                                name="street"
                                value={street}
                            />
                            <OrmaInput
                                label="Numero Exterior*"
                                type="text"
                                id="ext"
                                required
                                onChange={handleChange}
                                name="ext"
                                value={ext}
                            />
                        </div>
                        <div className="register-form-two-el">
                            <OrmaInput
                                label="Numero Interior"
                                type="text"
                                id="int"
                                onChange={handleChange}
                                name="int"
                                value={int}
                            />
                            <OrmaInput
                                label="Colonia*"
                                type="text"
                                id="hood"
                                required
                                onChange={handleChange}
                                name="hood"
                                value={hood}
                            />
                        </div>
                        <div className="register-form-two-el">
                            <Select
                                label="Estado*:"
                                id="state"
                                options={options}
                                name="state"
                                onChange={handleChange}
                                value={state}
                            />
                            <OrmaInput
                                label="Ciudad*"
                                type="text"
                                id="city"
                                required
                                onChange={handleChange}
                                name="city"
                                value={city}
                            />
                        </div>
                        <div className="register-form-two-el">
                            <OrmaInput
                                label="CP*"
                                type="text"
                                id="pc"
                                required
                                onChange={handleChange}
                                name="pc"
                                value={pc}
                            />
                        </div>
                    </div>
                </div>
                <div className="register-form-extras">
                    <OrmaInput
                        label="Nombre completo de uno de los padres*"
                        type="text"
                        id="parentName"
                        required
                        onChange={handleChange}
                        name="parentName"
                        value={parentName}
                    />
                    <div className="register-form-two-el">
                        <OrmaInput
                            label="Aceptar Terminos y condiciones*"
                            type="checkbox"
                            id="terms"
                            required
                            name="terms"
                            onChange={handleCheck}
                            value={terms}
                        />
                        <OrmaInput
                            label="Aceptar Politicas de Privacidad*"
                            type="checkbox"
                            required
                            id="privacy"
                            name="privacy"
                            onChange={handleCheck}
                            value={privacy}
                        />
                    </div>
                </div>
                <Button type="submit">Registrarse</Button>
            </form>
        </div>
    );
};

export default RegisterForm;
