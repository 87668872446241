import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './toggleButton.style.scss';
import { setIsPreview } from '../../store/toggleButton/toggleButton.actions';

const ToggleButton = () => {
    const dispatch = useDispatch();
    const isPreview = useSelector(state => state.toggleButton.isPreview);

    const handlePreviewClick = () => {
        dispatch(setIsPreview(true));
    };

    const handleListClick = () => {
        dispatch(setIsPreview(false));
    };

    return (
        <div className="toggle-button">
        <button
            className={`btnFilesPreview ${!isPreview ? 'active' : ''}`}
            onClick={handleListClick}
        >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Im0xMCAxNi40bC00LTRMNy40IDExbDIuNiAyLjZMMTYuNiA3TDE4IDguNHoiLz48L3N2Zz4=" alt="" className='imgSelectedList' />
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0zIDE4di0yaDE4djJ6bTAtNXYtMmgxOHYyem0wLTVWNmgxOHYyeiIvPjwvc3ZnPg==" alt="icon-filesList" />
        </button>
        <button
            className={`btnFilesList ${isPreview ? 'active' : ''}`}
            onClick={handlePreviewClick}
        >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Im0xMCAxNi40bC00LTRMNy40IDExbDIuNiAyLjZMMTYuNiA3TDE4IDguNHoiLz48L3N2Zz4=" alt="" className='imgSelectedPreview' />
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik00IDRoNHY0SDR6bTAgNmg0djRINHptNCA2SDR2NGg0em0yLTEyaDR2NGgtNHptNCA2aC00djRoNHptLTQgNmg0djRoLTR6TTIwIDRoLTR2NGg0em0tNCA2aDR2NGgtNHptNCA2aC00djRoNHoiLz48L3N2Zz4=" alt="icon-files" />
        </button>
    </div>
    );
};

export default ToggleButton;
