export const defaultFormFields = {
    email: "",
    password: "",
    reEnteredPassword: "",
    name: "",
    lastName: "",
    secondLastName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    phoneNumber: "",
    street: "",
    ext: "",
    int: "",
    hood: "",
    city: "",
    state: "",
    pc: "",
    parentName: "",
    terms: false,
    privacy: false,
};

export const defaultDateFields = {
    startDate: (new Date().toISOString()).substring(0, 10),
    endDate: (new Date().toISOString()).substring(0, 10),
};

export const defaultPasswordFormFields = {
    password: "",
    newPassword: "",
    reEnteredPassword: "",
};

export const defaultProfileFormFields = {
    name: "Maria",
    lastName: "Gomez",
    secondLastName: "Ramirez",
    dateOfBirth: "2024-03-20",
    placeOfBirth: "Jalisco",
    phoneNumber: "3315512459",
    street: "Joaquin Angulo",
    ext: "709",
    int: "01",
    hood: "Americana",
    city: "Guadalajara",
    state: "Jalisco",
    pc: "95600",
    parentName: "Jose Antonio Gomez Hernandez",
};

export const defaultValidators = {
    min: false,
    num: false,
    capital: false,
};

export const validatePassword = (str, setErrors) => {
    setErrors({
        min: str.length >= 8,
        num: /\d/.test(str),
        capital: /[A-Z]/.test(str),
    });
};

export const validateDate = (date) => {
    if (date) {
        return date.substring(0, 10);
    }
};

export const formatDate = dateString => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();

    const monthsInSpanish = [
        "ENE", "FEB", "MAR", "ABR", "MAY", "JUN",
        "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"
    ];

    return `${day.toString().padStart(2, '0')}/${monthsInSpanish[month]}/${year}`;
}
