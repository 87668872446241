import React, { useState } from 'react'
import FormInput from '../form-input/form-input.component'
import Button from '../button/button.component';
import { useDispatch } from 'react-redux';
import { setModalType } from '../../store/modal/modal.action';
import { forgotPass } from '../../utils/api/api';

const defaultFormFields = {
    email: "",
    password: "",
};

const ForgotPassword = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email } = formFields;
    const dispatch = useDispatch();
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        forgotPass(formFields);
        alert("Enviaremos un mail a tu cuenta con las instrucciones")
        dispatch(setModalType('login'))
    }
    const handleRegister = () => {
        dispatch(setModalType('register'))
    }
    return (
        <div className="login-form-container">
            <div className="login-form-title-container">
                <span className="login-form-title">Iniciar Sesión</span>
            </div>
            <form className="login-form-first" onSubmit={handleSubmit}>
                <div className='login-form-input-container'>
                    <FormInput
                        label="Correo Electronico"
                        type="text"
                        registerForm
                        required
                        onChange={handleChange}
                        name="email"
                        value={email}
                    />
                </div>
                <p className='forgot-password' onClick={handleRegister}>¿No tienes cuenta? Registrate aqui</p>
                <Button type="submit">Recuperar Contraseña</Button>
            </form>
        </div>
    )
}

export default ForgotPassword