export const getModalAppElement = (modalType) => {
    switch (modalType) {
        case "topBar":
            return document.getElementById("whole-navigation-container");
        case "register":
            return document.getElementById("whole-navigation-container");
        case "login":
            return document.getElementById("whole-navigation-container");
        case "filter":
            return document.getElementById("portal-route-container");
        default:
            return null;
    }
};
