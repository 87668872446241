import React from "react";

import "./cardOurServicies.style.scss";

const CardOurServicies = ({ text, src }) => (
    <div className="containerCardOurServicies">
        <img src={src} alt="ourServices" className="imgOurServicies" />
        <div className="containerTextOurServicies">
            <h3 className="textOurServicies">{text}</h3>
        </div>
    </div>
);

export default CardOurServicies;
