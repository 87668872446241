import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditNoteIcon from '@mui/icons-material/EditNote';
import './topBarModal.styles.scss'
import { useDispatch } from "react-redux";
import { setModalType } from "../../store/modal/modal.action";

const TopBarModal = () => {
    const dispatch = useDispatch()
    const handleRegister = () => {
        dispatch(setModalType("register"));
    }
    const handleLogin = () => {
        dispatch(setModalType("login"));
    }
    return (
        <div className="menu-modal-container">
            <span className="menu-modal-login" onClick={handleRegister}>
                <EditNoteIcon className="menu-modal-login-icon" /> Registrarse
            </span>
            <span className="menu-modal-login" onClick={handleLogin}>
                <AccountCircleIcon className="menu-modal-login-icon" /> Iniciar Sesión
            </span>
        </div>
    )
};

export default TopBarModal;
