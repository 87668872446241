import React from 'react'
import OrmaInput from '../orma-input/ormaInput.component'
import { selectUser } from '../../store/user/user.selector';
import { useSelector } from 'react-redux';
import { validateDate } from '../../utils/helpers/passwordValidator';
import './adminForm.styles.scss'

const AdminForm = () => {
    const { searchedUser } = useSelector(selectUser);
    const modifiedUser = { ...searchedUser, dateOfBirth: validateDate(searchedUser.dateOfBirth) };
    const {
        firstName,
        lastName,
        secondLastName,
        dateOfBirth,
        placeOfBirth,
        phone,
        street,
        extNumber,
        insideNumber,
        hood,
        city,
        state,
        pc,
        parentFullName,
    } = modifiedUser;
    return (
        <form className='admin-form-container'>
            <span className="admin-form-title">Información de la cuenta:</span>
            <div className="admin-edit-form">
                <OrmaInput
                    label="Nombre(s)"
                    type="text"
                    id="firstName"
                    required
                    readOnly={true}
                    name="firstName"
                    value={firstName}
                />
                <OrmaInput
                    label="Primer Apellido*"
                    type="text"
                    id="lastName"
                    required
                    readOnly={true}
                    name="lastName"
                    value={lastName}
                />
            </div>
            <div className="admin-edit-form">
                <OrmaInput
                    label="Segundo Apellido"
                    type="text"
                    id="secondLastName"
                    readOnly={true}
                    name="secondLastName"
                    value={secondLastName}
                />
                <OrmaInput
                    label="Fecha de Nacimiento*"
                    type="date"
                    id="dateOfBirth"
                    readOnly={true}
                    name="dateOfBirth"
                    value={dateOfBirth}
                />
            </div>
            <div className="admin-edit-form">
                <OrmaInput
                    label="Lugar de Nacimiento(Estado)"
                    type="text"
                    id="placeOfBirth"
                    readOnly={true}
                    name="placeOfBirth"
                    value={placeOfBirth}
                />
                <OrmaInput
                    label="#Celular"
                    type="text"
                    id="phone"
                    readOnly={true}
                    name="phone"
                    value={phone}
                />
            </div>

            <span className="admin-form-title">Domicilio en México:</span>
            <div className="admin-edit-form">
                <OrmaInput
                    label="Calle"
                    type="text"
                    id="street"
                    readOnly={true}
                    name="street"
                    value={street}
                />
                <OrmaInput
                    label="#Exterior"
                    type="text"
                    id="extNumber"
                    readOnly={true}
                    name="extNumber"
                    value={extNumber}
                />
                <OrmaInput
                    label="#Interior"
                    type="text"
                    id="insideNumber"
                    readOnly={true}
                    name="insideNumber"
                    value={insideNumber}
                />
            </div>
            <div className="admin-edit-form">
                <OrmaInput
                    label="Colonia"
                    type="text"
                    id="hood"
                    readOnly={true}
                    name="hood"
                    value={hood}
                />
                <OrmaInput
                    label="Ciudad"
                    type="text"
                    id="city"
                    readOnly={true}
                    name="city"
                    value={city}
                />
            </div>
            <div className="admin-edit-form">
                <OrmaInput
                    label="Estado"
                    type="text"
                    id="state"
                    readOnly={true}
                    name="state"
                    value={state}
                />
                <OrmaInput
                    label="CP"
                    type="text"
                    id="pc"
                    readOnly={true}
                    name="pc"
                    value={pc}
                />
            </div>
            <div className="admin-edit-form ">
                <OrmaInput
                    label="Nombre completo de uno de los padres"
                    type="text"
                    id="parentFullName"
                    readOnly={true}
                    name="parentFullName"
                    value={parentFullName}
                />
            </div>
        </form>
    )
}

export default AdminForm