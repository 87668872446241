import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import './passwordValidator.styles.scss'

const PasswordValidator = ({ errors }) => (
    <div className="validation-messages">
        {Object.entries(errors).map(([key, value]) => (
            <div key={key} className="register-password-validator">
                {value ? (
                    <CheckCircleIcon className="register-password-icon positive" />
                ) : (
                    <CancelIcon className="register-password-icon negative" />
                )}
                <p className="register-password-paragraph">
                    {key === "min" && "Debe tener al menos 8 digitos"}
                    {key === "num" && "Debe tener al menos un Numero"}
                    {key === "capital" && "Debe tener una mayuscula"}
                </p>
            </div>
        ))}
    </div>
);

export default PasswordValidator;
