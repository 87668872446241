import React from "react";
import ReactModal from "react-modal";
import "./modal.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
    selectIsOpen,
    selectModalType,
} from "../../store/modal/modal.selector";
import { getModalAppElement } from "../../utils/helpers/modalAppElementHelper";
import TopBarModal from "../topBarModal/topBarModal.component";
import RegisterForm from "../registerForm/registerForm.component";
import LoginForm from "../loginForm/loginForm.component";
import FilterForm from "../filterForm/filterForm.component";
import { setIsOpen } from "../../store/modal/modal.action";
import ForgotPassword from "../forgotPassword/forgotPassword.component";

const Modal = () => {
    const isOpen = useSelector(selectIsOpen);
    const modalType = useSelector(selectModalType);
    const appElement = getModalAppElement(modalType);
    const dispatch = useDispatch();
    let modalComponent;

    const handleCloseModal = () => {
        dispatch(setIsOpen(false));
    };

    switch (modalType) {
        case "topBar":
            modalComponent = <TopBarModal />;
            break;
        case "register":
            modalComponent = <RegisterForm />;
            break;
        case "login":
            modalComponent = <LoginForm />;
            break;
        case "forgot":
            modalComponent = <ForgotPassword />;
            break;
        case "filter":
            modalComponent = <FilterForm />;
            break;
        default:
            modalComponent = null;
    }
    const modalStyle = {
        content: {
            top: "0",
            left: "50%",
            right: "50%",
            bottom: "0",
            background:
                modalType === "register" || modalType === "filter" ? "#fff" : "#e6e6e6",
            padding: "40px",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "105px",
            pointerEvents: "auto",
            transform: "translateX(-50%)",
            width: modalType === "filter" ? "400px" : "95%",
            height: modalType === "filter" ? "400px" : "auto",
            boxShadow: modalType === "filter" ? "0 0 100px #C2C2C2" : "none",
            transition: "all ease 0.5s",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0)", // Adjust opacity as needed
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    };
    return (
        <ReactModal
            isOpen={isOpen}
            appElement={appElement}
            style={modalStyle}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleCloseModal}
        >
            {modalComponent}
        </ReactModal>
    );
};

export default Modal;
