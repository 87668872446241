import { applyMiddleware, compose, createStore } from "redux"
import storage from "redux-persist/lib/storage"
import persistReducer from "redux-persist/es/persistReducer"
import persistStore from "redux-persist/es/persistStore";
import createSagaMiddleware from "redux-saga"
import { rootReducer } from "./root-reducer"
import { rootSaga } from "./root-saga";

const persistConfig = {
    key: "root",
    storage,
    whitelist: [""],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();

const midleWares = [sagaMiddleware];

const composeEnhancer =
    (process.env.NODE_ENV !== "production" &&
        window &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const composedEnhancers = composeEnhancer(applyMiddleware(...midleWares))

export const store = createStore(
    persistedReducer,
    undefined,
    composedEnhancers
)

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
