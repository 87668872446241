import React, { useEffect, useState } from "react";
import "./homePortal.styles.scss";
import OrmaInput from "../../components/orma-input/ormaInput.component";
import ToggleButton from "../../components/toggleButton/toggleButton.component";
import FilePreview from "../../components/filePreview/filePreview.component";
import FileList from "../../components/fileList/fileList.component";
import { selectIsPreview } from "../../store/toggleButton/toggleButton.selector";
import { useSelector } from 'react-redux';
import { selectUser } from "../../store/user/user.selector";
import { getAccountById } from '../../utils/api/api';
import { downloadFile } from '../../utils/api/api';
import { formatDate } from "../../utils/helpers/passwordValidator";


const HomePortal = () => {
    const isPreview = useSelector(selectIsPreview);
    const userData = useSelector(selectUser);
    const [files, setFiles] = useState([]);
    const registerDate = formatDate(userData.user.createdAt);
    const idUser = userData.user.id;

    useEffect(() => {
        const fetchAccountData = async () => {
            try {
                const response = await getAccountById(idUser);
                if (response && response.data && response.data.files && Array.isArray(response.data.files)) {
                    setFiles(response.data.files);
                } else {
                    // console.error("No hay archivos o la estructura de datos no es la esperada.");
                }
            } catch (error) {
                // console.error("Error al obtener datos de la cuenta", error);
            }
        };
        if (idUser) {
            fetchAccountData();
        }
    }, [idUser]);

    const downloadClick = async (key) => {
        try {
            const response = await downloadFile(key);
            console.log(response)
            if (response.statusCode === 200) {
                window.open(response.data);
            } else {
                throw new Error('Failed to download file');
            }
        } catch (error) {
            console.log(error)
        }
    };



    return (
        <div className="portal-home-container">
            <header className="header-portal">
                <div className="welcome">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDEuMjVhNC43NSA0Ljc1IDAgMSAwIDAgOS41YTQuNzUgNC43NSAwIDAgMCAwLTkuNU04Ljc1IDZhMy4yNSAzLjI1IDAgMSAxIDYuNSAwYTMuMjUgMy4yNSAwIDAgMS02LjUgME0xMiAxMi4yNWMtMi4zMTMgMC00LjQ0NS41MjYtNi4wMjQgMS40MTRDNC40MiAxNC41NCAzLjI1IDE1Ljg2NiAzLjI1IDE3LjV2LjEwMmMtLjAwMSAxLjE2Mi0uMDAyIDIuNjIgMS4yNzcgMy42NjJjLjYyOS41MTIgMS41MS44NzcgMi43IDEuMTE3YzEuMTkyLjI0MiAyLjc0Ny4zNjkgNC43NzMuMzY5czMuNTgtLjEyNyA0Ljc3NC0uMzY5YzEuMTktLjI0IDIuMDctLjYwNSAyLjctMS4xMTdjMS4yNzktMS4wNDIgMS4yNzctMi41IDEuMjc2LTMuNjYyVjE3LjVjMC0xLjYzNC0xLjE3LTIuOTYtMi43MjUtMy44MzZjLTEuNTgtLjg4OC0zLjcxMS0xLjQxNC02LjAyNS0xLjQxNE00Ljc1IDE3LjVjMC0uODUxLjYyMi0xLjc3NSAxLjk2MS0yLjUyOGMxLjMxNi0uNzQgMy4xODQtMS4yMjIgNS4yOS0xLjIyMmMyLjEwNCAwIDMuOTcyLjQ4MiA1LjI4OCAxLjIyMmMxLjM0Ljc1MyAxLjk2MSAxLjY3NyAxLjk2MSAyLjUyOGMwIDEuMzA4LS4wNCAyLjA0NC0uNzI0IDIuNmMtLjM3LjMwMi0uOTkuNTk3LTIuMDUuODExYy0xLjA1Ny4yMTQtMi41MDIuMzM5LTQuNDc2LjMzOWMtMS45NzQgMC0zLjQyLS4xMjUtNC40NzYtLjMzOWMtMS4wNi0uMjE0LTEuNjgtLjUwOS0yLjA1LS44MWMtLjY4NC0uNTU3LS43MjQtMS4yOTMtLjcyNC0yLjYwMSIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+"
                        alt="icon-user"
                    />
                    <p className="portal-header">Hola {userData.user.firstName}, !bienvenido!</p>
                </div>
                <div className="date">
                    <p>
                        <span>Miembro desde:</span> {registerDate}
                    </p>
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Im0xMC42IDEzLjhsLTIuMTUtMi4xNXEtLjI3NS0uMjc1LS43LS4yNzV0LS43LjI3NXEtLjI3NS4yNzUtLjI3NS43dC4yNzUuN0w5LjkgMTUuOXEuMy4zLjcuM3QuNy0uM2w1LjY1LTUuNjVxLjI3NS0uMjc1LjI3NS0uN3QtLjI3NS0uN3EtLjI3NS0uMjc1LS43LS4yNzV0LS43LjI3NXpNMTIgMjJxLTIuMDc1IDAtMy45LS43ODh0LTMuMTc1LTIuMTM3cS0xLjM1LTEuMzUtMi4xMzctMy4xNzVUMiAxMnEwLTIuMDc1Ljc4OC0zLjl0Mi4xMzctMy4xNzVxMS4zNS0xLjM1IDMuMTc1LTIuMTM3VDEyIDJxMi4wNzUgMCAzLjkuNzg4dDMuMTc1IDIuMTM3cTEuMzUgMS4zNSAyLjEzOCAzLjE3NVQyMiAxMnEwIDIuMDc1LS43ODggMy45dC0yLjEzNyAzLjE3NXEtMS4zNSAxLjM1LTMuMTc1IDIuMTM4VDEyIDIybTAtMnEzLjM1IDAgNS42NzUtMi4zMjVUMjAgMTJxMC0zLjM1LTIuMzI1LTUuNjc1VDEyIDRROC42NSA0IDYuMzI1IDYuMzI1VDQgMTJxMCAzLjM1IDIuMzI1IDUuNjc1VDEyIDIwbTAtOCIvPjwvc3ZnPg=="
                        alt="icon-check"
                    />
                </div>
            </header>
            <div className="acount-information">
                <div className="containerInformation">
                    <p>Informacion de la cuenta:</p>
                    <div className="contentOrmaInput">
                        <OrmaInput
                            label="CURP"
                            id="name"
                            name="name"
                            type="text"
                            value={userData.user.curp}
                            readOnly={true}
                        />
                        <OrmaInput
                            label="NSS"
                            id="name"
                            name="name"
                            type="text"
                            value={userData.user.nss}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="containerInformation">
                    <p>Informacion de la supscripcion:</p>
                    <div className="contentOrmaInput">
                        <OrmaInput
                            label="Estatus"
                            id="name"
                            name="name"
                            type="text"
                            value={userData.user.status}
                            readOnly={true}
                        />
                        <OrmaInput
                            label="Vencimiento"
                            id="name"
                            name="name"
                            type="text"
                            value={formatDate(userData.user.membershipValidUntil)}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
            <div className="files-content">
                <div className="headerFilesContent">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik00IDIwcS0uODI1IDAtMS40MTItLjU4N1QyIDE4VjZxMC0uODI1LjU4OC0xLjQxMlQ0IDRoNmwyIDJoOHEuODI1IDAgMS40MTMuNTg4VDIyIDhIMTEuMTc1bC0yLTJINHYxMmwyLjQtOGgxNy4xbC0yLjU3NSA4LjU3NXEtLjIuNjUtLjczNyAxLjAzOFQxOSAyMHptMi4xLTJIMTlsMS44LTZINy45em0wIDBsMS44LTZ6TTQgOFY2eiIvPjwvc3ZnPg=="
                        alt="icon-folder"
                    />
                    <p>Mis archivos</p>
                </div>
                <div className="contentToggleButton">
                    <p>
                        Da click en el archivo para generar una vista previa o para descarga
                        directa
                    </p>
                    <ToggleButton />
                </div>
                <div className="container-files">
                    {isPreview ? (
                        <div className="filesPreview">
                            {files.map((file, index) => (
                                <FilePreview
                                    key={index}
                                    nameFile={file.fileName}
                                    srcIcon="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0xNCAySDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJWOHptNCAxOEg2VjRoN3Y1aDV6Ii8+PC9zdmc+"
                                    srcFile={file.url}
                                    downloadClick={() => downloadClick(file.url)}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={`filesListContainer ${!isPreview ? "active" : ""}`}>
                            {files.map((file, index) => (
                                <FileList
                                    key={index}
                                    fileName={file.fileName}
                                    srcIconFile="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0xNCAySDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJWOHptNCAxOEg2VjRoN3Y1aDV6Ii8+PC9zdmc+"
                                    srcFile={file.url}
                                    downloadClick={() => downloadClick(file.url)}
                                    srcDownload="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0xMyAzdjkuNTg2bDQtNEwxOC40MTQgMTBMMTIgMTYuNDE0TDUuNTg2IDEwTDcgOC41ODZsNCA0VjN6TTMgMThoMTh2MkgzeiIvPjwvc3ZnPg=="
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomePortal;
