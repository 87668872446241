import React, { useEffect, useState } from "react";
import "./topBar.styles.scss";
import mainLogo from "../../assets/main-logo.jpeg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIsOpen } from "../../store/modal/modal.selector";
import { setIsOpen, setModalType } from "../../store/modal/modal.action";

const TopBar = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const isOpen = useSelector(selectIsOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setIsSticky(currentScrollPos > prevScrollPos && currentScrollPos > 100);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);
    const handleRegister = () => {
        if (isOpen) {
            dispatch(setIsOpen(false))
        } else {
            dispatch(setModalType("register"));
            dispatch(setIsOpen(true));
        }
    }
    const handleLogin = () => {
        if (isOpen) {
            dispatch(setIsOpen(false))
        } else {
            dispatch(setModalType("login"));
            dispatch(setIsOpen(true));
        }
    }
    return (
        <div
            className={`top-bar-container top-bar-sticky ${isSticky ? "top-bar-hidden" : ""
                }`}
        >
            <div className="top-bar-logo-container">
                <Link className='top-bar-logo-element' to='/'>
                    <img className="top-bar-logo" src={mainLogo} alt="fireSpot" />
                </Link>
                <hr className="top-bar-hr" />
                <div className="top-bar-title-container">
                    <span className="top-bar-title">ORMA</span>
                    <span className="top-bar-subtitle">Servicios</span>
                </div>
            </div>
            <div className="top-bar-items-container">
                <span className="top-bar-login" onClick={handleRegister}>
                    <EditNoteIcon className="top-bar-login-icon" /> Registrate
                </span>
                <span className="top-bar-login" onClick={handleLogin}>
                    <AccountCircleIcon className="top-bar-login-icon" /> Iniciar Sesión
                </span>
            </div>
        </div>
    );
};

export default TopBar;
