import React from 'react'
import './burger.styles.scss'
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpen } from '../../store/modal/modal.selector';
import { setIsOpen, setModalType } from '../../store/modal/modal.action';

const Burger = () => {
    const isOpen = useSelector(selectIsOpen);
    const dispatch = useDispatch();
    const handleClick = () => {
        if (isOpen) {
            dispatch(setIsOpen(false))
        } else {
            dispatch(setModalType("topBar"));
            dispatch(setIsOpen(true));
        }
    };
    return (
        <div className='burger-container' onClick={handleClick}>
            <i className={`burger-i ${isOpen ? 'open' : 'close'} `} ></i>
            <i className={`burger-i ${isOpen ? 'open' : 'close'} `} ></i>
            <i className={`burger-i ${isOpen ? 'open' : 'close'} `} ></i>
        </div>
    )
}

export default Burger