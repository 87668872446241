import React, { useState } from "react";
import "./footer.styles.scss";
import FormInput from "../form-input/form-input.component";
import Button from "../button/button.component";
import { sendContactMail } from "../../utils/api/api";

const defaultFormFields = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
};

const Footer = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { firstName, lastName, email, message } = formFields;
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleContactSubmit = (event) => {
        event.preventDefault();
        sendContactMail(formFields);
        alert('Gracias por contactarnos!')
        setFormFields(defaultFormFields)
    }
    return (
        <>
            <div className="footer-container">
                <div className="footer-element">
                    <h1 className="footer-element-title">Contáctanos</h1>
                    <span className="footer-element-span">
                        Para acceder a todos estos beneficios solo necesitas: Ser Mexicano, conocer
                        tus datos generales de nacimiento y sobre todo estar seguro de QUERER
                        UNA MEJOR CALIDAD DE VIDA, para TI y tu FAMILIA.
                    </span>
                </div>
                <div className="footer-element">
                    <h1 className="footer-element-title">Dirección:</h1>
                    <span className="footer-element-span">
                        Rocklin, CA
                    </span>
                    <h1 className="footer-element-title">Email:</h1>
                    <span className="footer-element-span">contacto@ormaservicios.com</span>
                    <h1 className="footer-element-title">Teléfono:</h1>
                    <span className="footer-element-span">

                    </span>
                    <div className="footer-element-footer">
                        <span className="footer-element-footer-link">Facebook</span>
                        <span className="footer-element-footer-link">Linkedin</span>
                        <span className="footer-element-footer-link">Youtube</span>
                    </div>
                </div>
                <div className="footer-element">
                    <h1 className="footer-element-title">Contáctanos</h1>
                    <form className="footer-element-form" onSubmit={handleContactSubmit}>
                        <div className="footer-element-form-name">
                            <FormInput
                                label="Nombre"
                                type="text"
                                required
                                onChange={handleChange}
                                name="firstName"
                                value={firstName}
                            />
                            <FormInput
                                label="Apellido"
                                type="text"
                                required
                                onChange={handleChange}
                                name="lastName"
                                value={lastName}
                            />
                        </div>
                        <FormInput
                            label="Email"
                            type="email"
                            required
                            onChange={handleChange}
                            name="email"
                            value={email}
                        />
                        <FormInput
                            label="Mensaje"
                            type="text"
                            required
                            onChange={handleChange}
                            name="message"
                            value={message}
                        />
                        <Button type='submit'>Enviar</Button>
                    </form>
                </div>
                <h1 className="footer-element-back-to-top" onClick={scrollToTop}>Volver a arriba</h1>
            </div>
            <div className="footer-rights">
                <span className="footer-rights-text">
                    ©️2024 ORMA Servicios - Built in Rocklin 🇺🇸 and El Grullo 🇲🇽
                </span>
            </div>
        </>
    );
};

export default Footer;
