import React from "react";
import "./ormaInput.styles.scss";

const OrmaInput = ({ label, id, ...otherProps }) => (
    <div className="orma-group">
        <label htmlFor={id} className="orma-input-label">
            {label}:
        </label>
        <input className="orma-input" {...otherProps} />
    </div>
);

export default OrmaInput;
