export const USER_ACTION_TYPES = {
    SET_USER: "user/SET_USER",
    FETCH_USER_START: "user/FETCH_USER_START",
    FETCH_USER_SUCCESS: "user/FETCH_USER_SUCCESS",
    FETCH_USER_FAILED: "user/FETCH_USER_FAILED",
    SET_SEARCHED_ACCOUNTS: "user/SET_SEARCHED_ACCOUNTS",
    FETCH_SEARCHED_USER: "user/FETCH_SEARCHED_USER",
    FETCH_SEARCHED_USER_SUCCESS: "user/FETCH_SEARCHED_USER_SUCCESS",
    FETCH_SEARCHED_USER_FAILED: "user/FETCH_SEARCHED_USER_FAILED",
};
