import React from 'react'
import './rightSidebar.styles.scss'

export const RightSidebar = () => {
    return (
        <div className='right-sidebar-container'>
            <span>ads</span>
        </div>
    )
}
