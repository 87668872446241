import React, { useState } from "react";
import "./filterForm.styles.scss";
import OrmaInput from "../orma-input/ormaInput.component";
import {
    defaultDateFields,
    validateDate,
} from "../../utils/helpers/passwordValidator";
import Button from "../button/button.component";
import { useDispatch } from "react-redux";
import { fetchSearchedUserSuccess, setSearchedAccounts } from "../../store/user/user.actions";
import { listAccountsByDate } from "../../utils/api/api";
import { setIsOpen } from "../../store/modal/modal.action";

const FilterForm = () => {
    const [formFields, setFormFields] = useState(defaultDateFields);
    const { startDate, endDate } = formFields;
    const dispatch = useDispatch();
    const currentDate = validateDate(new Date().toISOString());
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };
    const handleFilter = async (event) => {
        event.preventDefault();
        try {
            dispatch(fetchSearchedUserSuccess(''))
            const response = await listAccountsByDate(formFields);
            dispatch(setSearchedAccounts(response.data));
            dispatch(setIsOpen(false))
        } catch (error) {
            
        }
    };
    return (
        <div className="filter-form-container">
            <div className="filter-form-title-container">
                <h1 className="filter-form-title">Selecciona un rango de fechas</h1>
                <form
                    onSubmit={handleFilter}
                    className="register-form-form-container"
                >
                    <OrmaInput
                        label="Fecha de Inicio"
                        type="date"
                        id="startDate"
                        max={endDate}
                        required
                        onChange={handleChange}
                        name="startDate"
                        value={startDate}
                    />
                    <OrmaInput
                        label="Fecha Final"
                        type="date"
                        id="endDate"
                        required
                        onChange={handleChange}
                        name="endDate"
                        min={startDate}
                        max={currentDate}
                        value={endDate}
                    />
                    <Button type="submit">Aplicar</Button>
                </form>
            </div>
        </div>
    );
};

export default FilterForm;
