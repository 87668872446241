import React from "react";
import "./about.styles.scss";

import AboutContent from "../../components/aboutContent/aboutContent.component";
import TextInformation from "../../components/textInformation/textInformation.component";
import ProfileContent from "../../components/profileContent/profileContent.component";
import OurInformation from "../../components/ourInformartion/ourInformation.component";
import ProfileInformation from "../../components/profileInformation/profileInformation.component";
import ProfileContact from "../../components/profileContact/profileContact.component";

const About = () => (
    <div className="aboutContainer">
        <AboutContent>
            <TextInformation
                title="Nosotros"
                textInfo="I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a story and let your users know a little more about you."
            />

            <div className="content-ourInformation">
                <OurInformation
                    title="Our Expertise"
                    textInfo="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
                <OurInformation
                    title="Our Philosophy"
                    textInfo="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
                <OurInformation
                    title="Our Commitment to Results"
                    textInfo="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
                <OurInformation
                    title="Our Commitment to Results"
                    textInfo="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
            </div>
        </AboutContent>
        <div className="imgAnimation">
            <img
                src="https://static.wixstatic.com/media/bc018e_5ceff40b660440bc91ad130b25a7669b~mv2.jpg/v1/fill/w_980,h_753,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bc018e_5ceff40b660440bc91ad130b25a7669b~mv2.jpg"
                alt="team"
            />
        </div>
        <div className="container-textInformation">
            <TextInformation
                title="The Team"
                textInfo="I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a story and let your users know a little more about you."
            />
        </div>
        <ProfileContent>
            <div className="text-profileContent">
                <ProfileInformation
                    title="Deborah Lynch"
                    subtitle="Co-Founder"
                    info="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
                <ProfileContact phone="123-456-7890" mail="info@mysite.com" />
            </div>
            <div className="img-profileContent">
                <img
                    src="https://static.wixstatic.com/media/bc018e_c27bf351d877412b881ca0dfcece5edb~mv2.jpg/v1/fill/w_1039,h_717,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bc018e_c27bf351d877412b881ca0dfcece5edb~mv2.jpg"
                    alt=""
                />
            </div>
        </ProfileContent>
        <ProfileContent>
            <div className="img-profileContent">
                <img
                    src="https://static.wixstatic.com/media/bc018e_40873574f0d54cdb85364a72ac507396~mv2.jpg/v1/fill/w_1037,h_717,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bc018e_40873574f0d54cdb85364a72ac507396~mv2.jpg"
                    alt=""
                />
            </div>
            <div className="text-profileContent">
                <ProfileInformation
                    title="Deborah Lynch"
                    subtitle="Co-Founder"
                    info="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
                <ProfileContact phone="123-456-7890" mail="info@mysite.com" />
            </div>
        </ProfileContent>
        <ProfileContent>
            <div className="text-profileContent">
                <ProfileInformation
                    title="Deborah Lynch"
                    subtitle="Co-Founder"
                    info="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
                />
                <ProfileContact phone="123-456-7890" mail="info@mysite.com" />
            </div>
            <div className="img-profileContent">
                <img
                    src="https://static.wixstatic.com/media/bc018e_e1f410e46189438daf6a8fb23484c734~mv2.jpg/v1/fill/w_1037,h_717,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bc018e_e1f410e46189438daf6a8fb23484c734~mv2.jpg"
                    alt=""
                />
            </div>
        </ProfileContent>
    </div>
);

export default About;
