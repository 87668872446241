import React from 'react'
import './profileContact.style.scss'

const ProfileContact = ({phone, mail}) => {
    return (
        <div className='profileContact'>
           <p>Phone: {phone}</p>
           <p>Mail: {mail}</p>
        </div>
    )
}

export default ProfileContact