import React from "react";
import "./select-input.styles.scss";
const Select = ({ label, id, name, onChange, value, options }) => {
    return (
        <div className="selector-container">
            <label className="selector-label" htmlFor={id}>
                {label}
            </label>
            <select id={id} name={name} onChange={onChange} className="form-select" required>
                {value ?
                    <option defaultValue disabled>Selecciona un estado</option>
                    :
                    <option defaultValue>Selecciona un estado</option>
                }
                {options.map((option) => (
                    <option
                        key={option}
                        value={option}
                    >
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default Select;
