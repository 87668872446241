import React, { useState } from "react";
import ToggleButton from "../toggleButton/toggleButton.component";
import FilePreview from "../filePreview/filePreview.component";
import FileList from "../fileList/fileList.component";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/user/user.selector";
import { selectIsPreview } from "../../store/toggleButton/toggleButton.selector";
import "./adminHome.styles.scss";
import OrmaInput from "../orma-input/ormaInput.component";
import Button from "../button/button.component";
import { downloadFile, loadFiles } from "../../utils/api/api";
import { fetchSearchedUserStart } from "../../store/user/user.actions";

const fileInitialState = []

const AdminHome = () => {
    const isPreview = useSelector(selectIsPreview);
    const { searchedUser } = useSelector(selectUser);
    const [file, setFile] = useState(fileInitialState);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        const [files] = event.target.files;
        setFile(files);
    };
    const downloadClick = async (key) => {
        try {
            const response = await downloadFile(key);
            console.log(response)
            if (response.statusCode === 200) {
                window.open(response.data);
            } else {
                throw new Error('Failed to download file');
            }
        } catch (error) {
            console.log(error)
        }
    };
    const handleFileSubmit = async (event) => {
        event.preventDefault();
        const res = await loadFiles(file, searchedUser.id);
        if(res.statusCode !== 201){
            alert('Algo salio mal, intente de nuevo mas tarde')
        }
        else{
            console.log(res)
            alert("Archivo cargado con exito")
            dispatch(fetchSearchedUserStart(searchedUser.id));
            setFile([]);
        }
    };
    return (
        <div className="admin-portal-home-container">
            <div className="admin-files-content">
                <div className="admin-headerFilesContent">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik00IDIwcS0uODI1IDAtMS40MTItLjU4N1QyIDE4VjZxMC0uODI1LjU4OC0xLjQxMlQ0IDRoNmwyIDJoOHEuODI1IDAgMS40MTMuNTg4VDIyIDhIMTEuMTc1bC0yLTJINHYxMmwyLjQtOGgxNy4xbC0yLjU3NSA4LjU3NXEtLjIuNjUtLjczNyAxLjAzOFQxOSAyMHptMi4xLTJIMTlsMS44LTZINy45em0wIDBsMS44LTZ6TTQgOFY2eiIvPjwvc3ZnPg=="
                        alt="icon-folder"
                    />
                    <p>Archivos</p>
                </div>
                <form className="file-form-container" onSubmit={handleFileSubmit}>
                    <OrmaInput
                        type="File"
                        label="Subir archivo"
                        required
                        onChange={handleChange}
                        name="file"
                    />
                    <div className="file-form-button">
                        <Button type="submit">Subir</Button>{" "}
                    </div>
                </form>
                <div className="admin-contentToggleButton">
                    <p>
                        Da click en el archivo para generar una vista previa o para descarga
                        directa
                    </p>
                    <ToggleButton />
                </div>
                <div className="admin-container-files">
                    {isPreview ? (
                        <div className="admin-filesPreview">
                            {searchedUser.files.map((file, index) => (
                                <FilePreview
                                    key={index}
                                    nameFile={file.fileName}
                                    srcIcon="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0xNCAySDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJWOHptNCAxOEg2VjRoN3Y1aDV6Ii8+PC9zdmc+"
                                    srcFile={file.url}
                                    downloadClick={() => downloadClick(file.url)}
                                />
                            ))}
                        </div>
                    ) : (
                        <div
                            className={`admin-filesListContainer ${!isPreview ? "active" : ""
                                }`}
                        >
                            {searchedUser.files.map((file, index) => (
                                <FileList
                                    key={index}
                                    fileName={file.fileName}
                                    srcIconFile="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0xNCAySDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJWOHptNCAxOEg2VjRoN3Y1aDV6Ii8+PC9zdmc+"
                                    srcFile={file.url}
                                    downloadClick={() => downloadClick(file.url)}
                                    srcDownload="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGQ9Ik0xMyAzdjkuNTg2bDQtNEwxOC40MTQgMTBMMTIgMTYuNDE0TDUuNTg2IDEwTDcgOC41ODZsNCA0VjN6TTMgMThoMTh2MkgzeiIvPjwvc3ZnPg=="
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminHome;
