import React from 'react';
import './giftHome.style.scss';

import Button from '../../components/button/button.component'

const GiftHome = ({ src }) => {
    return (
        <div className='containerGif'>
            <video src={src} autoPlay loop muted className='gif'></video>
            <span className='gif-title'>ORMA<br />Servicios</span>
            <span className='gif-text'>Comprometidos por tu bienestar</span>
            <Button buttonType='inverted'>Registrate</Button>
        </div>
    )
};

export default GiftHome;
