import { USER_ACTION_TYPES } from "./user.types";


export const POSTS_INITIAL_STATE = {
    user: {},
    isLoading: false,
    isSearchLoading: false,
    error: null,
    searchedAccounts: [],
    searchedUser: {}
};

export const userReducer = (
    state = POSTS_INITIAL_STATE,
    action = {}
) => {
    const { type, payload } = action;

    switch (type) {
        case USER_ACTION_TYPES.SET_USER:
            return { ...state, user: payload, isLoading: false };
        case USER_ACTION_TYPES.FETCH_USER_START:
            return { ...state, isLoading: true };
        case USER_ACTION_TYPES.FETCH_USER_SUCCESS:
            return { ...state, user: payload, isLoading: false };
        case USER_ACTION_TYPES.FETCH_USER_FAILED:
            return { ...state, error: payload, isLoading: false };
        case USER_ACTION_TYPES.SET_SEARCHED_ACCOUNTS: 
            return { ...state, searchedAccounts: payload };
        case USER_ACTION_TYPES.FETCH_SEARCHED_USER:
            return { ...state, searchedUser: payload, isSearchLoading: true };
        case USER_ACTION_TYPES.FETCH_SEARCHED_USER_SUCCESS:
            return { ...state, searchedUser: payload, isSearchLoading: false };
        case USER_ACTION_TYPES.FETCH_SEARCHED_USER_FAILED:
            return { ...state, searchedUser: {}, isSearchLoading: false };
        default:
            return state;
    }
};
