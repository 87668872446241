import React from 'react'

import './filePreview.style.scss'


const FilePreview = ({srcFile, srcIcon, nameFile, downloadClick}) => {

    return (
        <div className='container-file'>
            <div className='headerFiles'>
                <img src={srcIcon} alt="iconFile" />
                <p>{nameFile}</p>
            </div>
            <img src={srcFile} alt="" className='imgFile'/>
            <button className='buttonDownload' onClick={downloadClick}>Descargar</button>
        </div>
    )
}

export default FilePreview
