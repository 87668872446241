import React from "react";
import "./searchBar.styles.scss";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({
    placeholder,
    onSearchChange,
    searchField,
    handleSearch,
}) => {
    return (
        <div className="webflow-style-input">
            <input
                className="search-box"
                type="search"
                placeholder={placeholder}
                onChange={onSearchChange}
                value={searchField}
            />
            <button type="submit" onClick={handleSearch} className="btn-search">
                <SearchIcon fontSize="large" />
            </button>
        </div>
    );
};

export default SearchBar;
