import React, { useState } from "react";
import "./adminPortal.styles.scss";
import SearchBar from "../searchBar/searchBar.component";
import Button from "../button/button.component";
import ListAccounts from "../listAccounts/listAccounts.component";
import { useDispatch } from "react-redux";
import { setIsOpen, setModalType } from "../../store/modal/modal.action";
import { listAccounts } from "../../utils/api/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/user.selector";
import AdminForm from "../adminForm/adminForm.component";
import AdminHome from "../adminHome/adminHome.component";
import { fetchSearchedUserSuccess, setSearchedAccounts } from "../../store/user/user.actions";

const AdminPortal = () => {
    const user = useSelector(selectUser);
    const { searchedUser } = useSelector(selectUser);
    const [searchField, setSearchField] = useState("");
    const dispatch = useDispatch();
    const handleFilter = () => {
        dispatch(setModalType("filter"));
        dispatch(setIsOpen(true));
    };

    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLocaleLowerCase();
        setSearchField(searchFieldString);
    };

    const handleSearch = async () => {
        try {
            dispatch(fetchSearchedUserSuccess(''))
            const response = await listAccounts(searchField);
            dispatch(setSearchedAccounts(response.data));
            setSearchField("");
        } catch (error) {
            alert("Error al obtener datos de la cuenta", error);
        }
    };

    return (
        <div className="admin-portal-container">
            <header className="admin-header-portal">
                <div className="admin-welcome">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMxZmRiYzUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDEuMjVhNC43NSA0Ljc1IDAgMSAwIDAgOS41YTQuNzUgNC43NSAwIDAgMCAwLTkuNU04Ljc1IDZhMy4yNSAzLjI1IDAgMSAxIDYuNSAwYTMuMjUgMy4yNSAwIDAgMS02LjUgME0xMiAxMi4yNWMtMi4zMTMgMC00LjQ0NS41MjYtNi4wMjQgMS40MTRDNC40MiAxNC41NCAzLjI1IDE1Ljg2NiAzLjI1IDE3LjV2LjEwMmMtLjAwMSAxLjE2Mi0uMDAyIDIuNjIgMS4yNzcgMy42NjJjLjYyOS41MTIgMS41MS44NzcgMi43IDEuMTE3YzEuMTkyLjI0MiAyLjc0Ny4zNjkgNC43NzMuMzY5czMuNTgtLjEyNyA0Ljc3NC0uMzY5YzEuMTktLjI0IDIuMDctLjYwNSAyLjctMS4xMTdjMS4yNzktMS4wNDIgMS4yNzctMi41IDEuMjc2LTMuNjYyVjE3LjVjMC0xLjYzNC0xLjE3LTIuOTYtMi43MjUtMy44MzZjLTEuNTgtLjg4OC0zLjcxMS0xLjQxNC02LjAyNS0xLjQxNE00Ljc1IDE3LjVjMC0uODUxLjYyMi0xLjc3NSAxLjk2MS0yLjUyOGMxLjMxNi0uNzQgMy4xODQtMS4yMjIgNS4yOS0xLjIyMmMyLjEwNCAwIDMuOTcyLjQ4MiA1LjI4OCAxLjIyMmMxLjM0Ljc1MyAxLjk2MSAxLjY3NyAxLjk2MSAyLjUyOGMwIDEuMzA4LS4wNCAyLjA0NC0uNzI0IDIuNmMtLjM3LjMwMi0uOTkuNTk3LTIuMDUuODExYy0xLjA1Ny4yMTQtMi41MDIuMzM5LTQuNDc2LjMzOWMtMS45NzQgMC0zLjQyLS4xMjUtNC40NzYtLjMzOWMtMS4wNi0uMjE0LTEuNjgtLjUwOS0yLjA1LS44MWMtLjY4NC0uNTU3LS43MjQtMS4yOTMtLjcyNC0yLjYwMSIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+"
                        alt="icon-user"
                        className="admin-img"
                    />
                    <p className="admin-portal-header">
                        Hola {user.user.firstName}, !bienvenido!
                    </p>
                </div>
            </header>
            <div className="admin-search-filter-container">
                <SearchBar
                    placeholder="Buscar Usuario"
                    onSearchChange={onSearchChange}
                    searchField={searchField}
                    handleSearch={handleSearch}
                />
                <div className="admin-profile-button">
                    <Button
                        disabled={searchField.length ? true : false}
                        onClick={handleFilter}
                    >
                        Filtrar
                    </Button>
                    <button
                        className={`btn-filter ${searchField.length ? "disabled" : ""}`}
                        onClick={handleFilter}
                        disabled={searchField.length ? true : false}
                    >
                        <img
                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMwZjUwNTciIGQ9Ik0xMCAxOHYtMmg0djJ6bS00LTV2LTJoMTJ2MnpNMyA4VjZoMTh2MnoiLz48L3N2Zz4="
                            alt="icon-filter"
                        />
                    </button>
                </div>
            </div>
            {searchedUser.id ? (
                <div>
                    <AdminForm />
                    <AdminHome />
                </div>
            ) : (
                <div className="container-list-accounts">
                    {user.searchedAccounts.map((account) => (
                        <ListAccounts
                            key={account.id}
                            id={account.id}
                            name={account.firstName}
                            lastName={account.lastName}
                            secondLastName={account.secondLastName}
                            phone={account.phone}
                            email={account.email}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default AdminPortal;
