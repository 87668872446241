import React from "react";
import "./fileList.style.scss";

const FileList = ({ srcIconFile, fileName, srcDownload, downloadClick }) => {
    return (
        <div className="fileList">
            <div>
                <img src={srcIconFile} alt="iconFile" />
                <p>{fileName}</p>
            </div>
            <div>
                <button onClick={downloadClick}>
                    <img src={srcDownload} alt="iconDownload" />
                </button>
            </div>
        </div>
    );
};

export default FileList;
