import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import OrmaInput from "../orma-input/ormaInput.component";
import PasswordValidator from "../passwordValidator/passwordValidator.component";
import { setIsOpen, setModalType } from '../../store/modal/modal.action';
import Button from "../button/button.component";
import {
    defaultFormFields,
    defaultValidators,
    validatePassword,
} from "../../utils/helpers/passwordValidator";
import "./passwordRecoveryForm.styles.scss";
import { passwordRecovery } from "../../utils/api/api";
import { useNavigate } from "react-router-dom";

const PasswordRecoveryForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [errors, setErrors] = useState(defaultValidators);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailParam = params.get("email");
        const tokenParam = params.get("token");

        if (emailParam) setEmail(emailParam);
        if (tokenParam) setToken(tokenParam);
    }, []);

    const { password, reEnteredPassword } = formFields;
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "password") {
            setFormFields({ ...formFields, [name]: value });
            validatePassword(value, setErrors);
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
    };
    const handleRegister = async (event) => {
        event.preventDefault();
        if (password !== reEnteredPassword) {
            alert("Las contraseñas deben coincidir");
            return;
        }
        if (errors.min && errors.num && errors.capital) {
            const response = await passwordRecovery(password, reEnteredPassword, token);
            if (response.error) {
                alert('Error: ' + response.error)
            } else {
                navigate('/')
                alert('Contraseña recuperada con Exito')
                dispatch(setIsOpen(true))
                dispatch(setModalType('login'))
            }
        } else {
            alert("La contraseña debe cumplir con los valores");
        }
    };

    return (
        <div className="password-recovery-form-container">
            <div className="password-recovery-form-title-container">
                <span className="password-recovery-form-title">
                    Recuperando contraseña
                </span>
                <span className="password-recovery-form-text">
                    Introduce tu nueva contraseña
                </span>
            </div>
            <form
                onSubmit={handleRegister}
                className="password-recovery-form-form-container"
            >
                <div className="password-recovery-form-first">
                    <OrmaInput
                        label="Correo Electronico*"
                        type="email"
                        id="email"
                        required
                        disabled
                        name="email"
                        value={email}
                    />
                    <div className="password-recovery-form-two-el">
                        <div className="register-password-input">
                            <OrmaInput
                                label="Contraseña*"
                                type="password"
                                id="password"
                                required
                                onChange={handleChange}
                                name="password"
                                value={password}
                            />
                            <OrmaInput
                                label="Confirmar Contraseña*"
                                type="password"
                                id="reEnteredPassword"
                                required
                                onChange={handleChange}
                                name="reEnteredPassword"
                                value={reEnteredPassword}
                            />
                        </div>
                        <PasswordValidator errors={errors} />
                    </div>
                </div>
                <Button type="submit">Cambiar Contraseña</Button>
            </form>
        </div>
    );
};

export default PasswordRecoveryForm;
