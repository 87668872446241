import React from 'react'
import './profileInformation.style.scss'

const ProfileInformation = ({ title, subtitle, info }) => {
    return (
        <div className='profileInformation'>
            <h3>{title}</h3>
            <h4>{subtitle}</h4>
            <p>{info}</p>
        </div>
    )
}

export default ProfileInformation