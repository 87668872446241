import { takeLatest, all, call, put } from "redux-saga/effects";
import { USER_ACTION_TYPES } from "./user.types";
import {
    fetchSearchedUserFailed,
    fetchSearchedUserSuccess,
    fetchUserFailed,
    fetchUserSuccess,
} from "./user.actions";
import { checkForExistingUser, getAccountById } from "../../utils/api/api";

export function* fetchUserAsync() {
    try {
        const user = yield call(checkForExistingUser);
        if (user) {
            yield put(fetchUserSuccess(user));
        }
    } catch (error) {
        yield put(fetchUserFailed(error));
    }
}

export function* onFetchUser() {
    yield takeLatest(USER_ACTION_TYPES.FETCH_USER_START, fetchUserAsync);
}

export function* userSaga() {
    yield all([call(onFetchUser)]);
}

export function* fetchSearchedUserAsync({ payload }) {
    try {
        const { data } = yield call(getAccountById, payload);
        yield put(fetchSearchedUserSuccess(data));
    } catch (error) {
        yield put(fetchSearchedUserFailed(error));
    }
}

export function* onFetchSearchedUser() {
    yield takeLatest(
        USER_ACTION_TYPES.FETCH_SEARCHED_USER,
        fetchSearchedUserAsync
    );
}

export function* searchedUserSaga() {
    yield all([call(onFetchSearchedUser)]);
}
