import { TOGGLE_ACTION_TYPES } from "./toggleButton.types";

export const TOGGLE_INITIAL_STATE = {
  isPreview: true,
};

export const ToggleButtonReducer = (state = TOGGLE_INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
    case TOGGLE_ACTION_TYPES.SET_IS_PREVIEW:
      return {
        ...state,
        isPreview: payload,
      };
    default:
      return state;
  }
};
