import React from 'react'
import './ourInformation.style.scss'

const OurInformation = ({ title, textInfo }) => {
    return (
      <div className='ourInformation'>
        <h1>{title}</h1>
        <p>{textInfo}</p>
      </div>
    );
  };

export default OurInformation;