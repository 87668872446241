import React from "react";
import "./home.styles.scss";
import GiftHome from "../../components/gift/giftHome.component";
import CardOurServicies from "../../components/card/cardOurServicies.component";
import Button from "../../components/button/button.component";
import { ReactComponent as SVG1 } from "../../assets/svg1.svg";
import { ReactComponent as SVG2 } from "../../assets/svg2.svg";
import { ReactComponent as SVG3 } from "../../assets/svg3.svg";

const Home = () => (
    <div className="home-container" id="home-container">
        <div className="giftHome">
            <GiftHome
                src="https://video.wixstatic.com/video/bc018e_6fd793b585c449aaa8ee6b8d783375aa/1080p/mp4/file.mp4"
                textInformation="ORMA Servicios"
            />
        </div>
        <div className="home-our-services">
            <h1 className="home-our-services-title">Nuestra Promesa</h1>
            <span className="home-our-services-text">
                Velar por el bienestar de nuestros afiliados, dándoles acceso a atención
                médica, servicios financieros y una vida de retiro digna en México.
            </span>
        </div>
        <div className="containerCardOurServiciesComponent">
            <CardOurServicies
                src="https://img.freepik.com/free-photo/close-up-doctor-with-stethoscope_23-2149191362.jpg?size=626&ext=jpg"
                text="Servicios de Salud"
            />
            <CardOurServicies
                src="https://cdn.pixabay.com/photo/2017/08/30/07/56/money-2696228_1280.jpg"
                text="Servicios Financieros"
            />
            <CardOurServicies
                src="https://cdn.pixabay.com/photo/2017/11/03/16/41/elderly-2914879_1280.jpg"
                text="Retiro"
            />
        </div>
        <div className="home-how-it-works-container">
            <div className="how-it-works-left">
                <span className="how-it-works-left-title">SABÍAS</span>
                <span className="how-it-works-left-title">QUE...</span>
                <div className="how-it-works-left-bottom">
                    <div className="how-it-works-card">
                        <SVG1 className="how-it-works-card-svg" />
                        <hr className="how-it-works-card-hr" />
                        <span className="how-it-works-card-title"></span>
                        <span className="how-it-works-card-text">
                            El Instituto Mexicano del Seguro Social (IMSS), es una
                            organización gubernamental y privada dedicada a brindar servicios
                            de salud y seguridad social a la población que cuente con
                            afiliación al propio instituto.
                        </span>
                    </div>
                    <div className="how-it-works-card">
                        <SVG2 className="how-it-works-card-svg" />
                        <hr className="how-it-works-card-hr" />
                        <span className="how-it-works-card-title"></span>
                        <span className="how-it-works-card-text">
                            En ORMA hacemos posible que nuestros afiliados puedan inscribirse
                            al IMSS y así gozar de los beneficios que esto conlleva, como son,
                            servicios médicos para ellos y su familia, acceso a medicamentos,
                            pensiones para adultos mayores, entre otros.***
                        </span>
                    </div>
                    <div className="how-it-works-card">
                        <SVG3 className="how-it-works-card-svg" />
                        <hr className="how-it-works-card-hr" />
                        <span className="how-it-works-card-title">Qué necesito?</span>
                        <span className="how-it-works-card-text">
                            <span>SER MEXICANO</span>
                            <p></p>
                            <span>CONOCER TU FECHA Y LUGAR DE NACIMIENTO</span>
                        </span>
                    </div>
                </div>
            </div>
            <img
                src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="how-it-works"
                className="how-it-works-img"
            />
        </div>
        <div className="why-choose-us-container">
            <span className="why-choose-us-title">Por qué elegirnos?</span>
            <div className="why-choose-us-card-container">
                <div className="why-choose-us-card">
                    <hr className="why-choose-us-card-hr" />
                    <span className="why-choose-us-card-title">
                        Accesible a todos los Mexicanos
                    </span>
                    <hr className="why-choose-us-card-hr" />
                    <span className="why-choose-us-card-title">Bajo Costo</span>
                </div>
                <div className="why-choose-us-card">
                    <hr className="why-choose-us-card-hr" />
                    <span className="why-choose-us-card-title">
                        Asesoría Integral en Español
                    </span>
                    <hr className="why-choose-us-card-hr" />
                    <span className="why-choose-us-card-title">
                        Cobertura Familiar (Aplican restricciones)
                    </span>
                </div>
                <div className="why-choose-us-card">
                    <hr className="why-choose-us-card-hr" />
                    <span className="why-choose-us-card-title">
                        15 Años de Experiencia
                    </span>
                    <hr className="why-choose-us-card-hr" />
                    <span className="why-choose-us-card-title">
                        Atención a Nuestros Clientes
                    </span>
                </div>
            </div>
        </div>
        <div className="plans-and-pricing-container">
            <span className="plans-and-pricing-title">Plans & Pricing</span>
            <span className="plans-and-pricing-text">
                A RANGE OF OPPORTUNITIES TO SUIT EVERY CLIENT
            </span>
            <Button buttonType="inverted">Learn More</Button>
        </div>
        <div className="home-last-image-container">
            <img
                className="home-last-image"
                src="https://cdn.pixabay.com/photo/2017/09/04/07/05/teotihuacan-2713130_1280.jpg"
                alt="board"
            />
        </div>
    </div>
);

export default Home;
