import React from 'react'
import './smallSideBar.styles.scss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchedUserSuccess, setSearchedAccounts, setUser } from '../../store/user/user.actions';
import { selectUser } from '../../store/user/user.selector';

const SmallSideBar = () => {
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(setUser(''))
        localStorage.setItem('token', '')
    }
    const handleResetSearch = () => {
        dispatch(fetchSearchedUserSuccess({}));
        dispatch(setSearchedAccounts([]));
    }
    return (
        <div className='small-sidebar-flex'>
            <div className='small-sidebar'>
                <div className='small-sidebar-profile'>
                    <Link className='small-profile-element' to='/portal'>
                        <div className='small-profile-photo'>
                            <AccountCircleOutlinedIcon className='small-profile-photo-icon' />
                        </div>
                        <h1 className='small-profile-h1'>{`${user.firstName} ${user.lastName}`}</h1>
                    </Link>
                </div>
                <Link className="small-sidebar-element" to="/portal" onClick={handleResetSearch}>
                    <HomeOutlinedIcon fontSize="large" />
                </Link>
                <Link className='small-sidebar-element' to="/portal/profile">
                    <AccountCircleOutlinedIcon fontSize="large" />
                </Link>
                <Link onClick={handleLogout} className='small-sidebar-element' to="/">
                    <LogoutIcon fontSize="large" />
                </Link>
                <div className='small-sidebar-element'>
                    <HelpOutlineIcon fontSize="large" />
                </div>
            </div>
        </div>
    )
}

export default SmallSideBar;