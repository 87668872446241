import React from 'react'
import './passwordRecovery.styles.scss'
import PasswordRecoveryForm from '../../components/passwordRecoveryForm/passwordRecoveryForm'

const PasswordRecovery = () => (
    <div className='password-recovery-container'>
        <PasswordRecoveryForm />
    </div>
)

export default PasswordRecovery