import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SmallSideBar from "../../components/smallSideBar/smallSideBar.component";
import Sidebar from "../../components/sidebar/sidebar.component";
import { RightSidebar } from "../../components/rigthSidebar/rightSidebar.component";
import "./portalNavigation.styles.scss";
import PortalTopBar from "../../components/portalTopBar/portalTopBar.component";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/user.selector";

const PortalNavigation = () => {
    const [is1200, setIs1200] = useState(false);
    const [is900, setIs900] = useState(false);
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    useEffect(() => {
        if (!user.user.id) {
            navigate("/");
        }
        if (window.innerWidth < 1200) {
            setIs1200(true);
        } else {
            setIs1200(false);
        }
        if (window.innerWidth < 950) {
            setIs900(false);
        } else {
            setIs900(true);
        }
        const updateBars = () => {
            if (window.innerWidth < 1200) {
                setIs1200(true);
            } else {
                setIs1200(false);
            }
            if (window.innerWidth < 950) {
                setIs900(false);
            } else {
                setIs900(true);
            }
        };
        window.addEventListener("resize", updateBars);

        return () => {
            window.removeEventListener("resize", updateBars);
        };
    }, [user.user]);

    return (
        <div className="whole-portal-navigation-container">
            <div className="navigation-portal-top-bar-container">
                <PortalTopBar />
            </div>
            <div className="portal-navigation-container">
                {is900 ? <Sidebar /> : <SmallSideBar />}
                <Outlet />
                {!is1200 && <RightSidebar />}
            </div>
        </div>
    );
};

export default PortalNavigation;
