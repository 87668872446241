import React from 'react'
import './sidebar.styles.scss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchSearchedUserSuccess, setSearchedAccounts, setUser } from '../../store/user/user.actions';
import { useSelector } from "react-redux";
import { selectUser } from '../../store/user/user.selector';

const Sidebar = () => {
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(setUser(''))
        localStorage.setItem('token', '')
    }
    const handleResetSearch = () => {
        dispatch(fetchSearchedUserSuccess({}));
        dispatch(setSearchedAccounts([]));
    }
    return (
        <div className='sidebar-flex'>
            <div className='sidebar'>
                <div className='sidebar-profile'>
                    <Link className='profile-element' to='/portal'>
                        <div className='profile-photo'>
                            <AccountCircleOutlinedIcon className='profile-photo-icon' />
                        </div>
                        <h1 className='profile-h1'>{`${user.firstName} ${user.lastName}`}</h1>
                    </Link>
                </div>
                <Link className="sidebar-element" to="/portal" onClick={handleResetSearch}>
                    <HomeOutlinedIcon fontSize="medium" />
                    <h1 className='sidebar-element-h1'>Inicio</h1>
                </Link>
                <Link className='sidebar-element' to="/portal/profile">
                    <AccountCircleOutlinedIcon />
                    <h1 className='sidebar-element-h1'>Perfil</h1>
                </Link>
                <Link onClick={handleLogout} className='sidebar-element' to="/">
                    <LogoutIcon />
                    <h1 className='sidebar-element-h1'>Cerrar Sesión</h1>
                </Link>
                <div className='sidebar-element'>
                    <HelpOutlineIcon />
                    <h1 className='sidebar-element-h1'>Ayuda</h1>
                </div>
            </div>
        </div>
    )
};

export default Sidebar;
