import React from "react";
import "./listAccounts.style.scss";
import { useDispatch } from "react-redux";
import { fetchSearchedUserStart } from "../../store/user/user.actions";

const ListAccounts = ({ id, name, phone, email, lastName,secondLastName }) => {
    const dispatch = useDispatch();
    const handleList = () => {
        dispatch(fetchSearchedUserStart(id));
    };
    return (
        <div className="list-accounts-container">
            <div className="list-accounts" onClick={handleList}>
                <div className="list-accounts-item">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjU2IDI1NiI+PHBhdGggZmlsbD0iIzAwZDFhZSIgZD0iTTEyOCAyNGExMDQgMTA0IDAgMSAwIDEwNCAxMDRBMTA0LjExIDEwNC4xMSAwIDAgMCAxMjggMjRNNzQuMDggMTk3LjVhNjQgNjQgMCAwIDEgMTA3Ljg0IDBhODcuODMgODcuODMgMCAwIDEtMTA3Ljg0IDBNOTYgMTIwYTMyIDMyIDAgMSAxIDMyIDMyYTMyIDMyIDAgMCAxLTMyLTMybTk3Ljc2IDY2LjQxYTc5LjY2IDc5LjY2IDAgMCAwLTM2LjA2LTI4Ljc1YTQ4IDQ4IDAgMSAwLTU5LjQgMGE3OS42NiA3OS42NiAwIDAgMC0zNi4wNiAyOC43NWE4OCA4OCAwIDEgMSAxMzEuNTIgMCIvPjwvc3ZnPg=="
                        alt="icon-user"
                    />
                    <p>{`${name} ${lastName} ${secondLastName}`}</p>
                </div>
                <div className="list-accounts-item">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMwMGQxYWUiIGQ9Ik02LjYyIDEwLjc5YzEuNDQgMi44MyAzLjc2IDUuMTUgNi41OSA2LjU5bDIuMi0yLjJjLjI4LS4yOC42Ny0uMzYgMS4wMi0uMjVjMS4xMi4zNyAyLjMyLjU3IDMuNTcuNTdhMSAxIDAgMCAxIDEgMVYyMGExIDEgMCAwIDEtMSAxQTE3IDE3IDAgMCAxIDMgNGExIDEgMCAwIDEgMS0xaDMuNWExIDEgMCAwIDEgMSAxYzAgMS4yNS4yIDIuNDUuNTcgMy41N2MuMTEuMzUuMDMuNzQtLjI1IDEuMDJ6Ii8+PC9zdmc+"
                        alt="icon-phone"
                    />
                    <p>{phone}</p>
                </div>
                <div className="list-accounts-item">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9IiMwMGQxYWUiIGQ9Im0yMCA4bC04IDVsLTgtNVY2bDggNWw4LTVtMC0ySDRjLTEuMTEgMC0yIC44OS0yIDJ2MTJhMiAyIDAgMCAwIDIgMmgxNmEyIDIgMCAwIDAgMi0yVjZhMiAyIDAgMCAwLTItMiIvPjwvc3ZnPg=="
                        alt="icon-email"
                    />
                    <p>{email}</p>
                </div>
            </div>
        </div>
    );
};

export default ListAccounts;
