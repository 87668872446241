import { MODAL_ACTION_TYPES} from "./modal.types"

export const MODAL_INITIAL_STATE = {
  isOpen: false,
  modalType: "",
};
  
  export const modalReducer = ( state = MODAL_INITIAL_STATE, action ) => {
    const { type, payload } = action;
    switch (type) {
      case MODAL_ACTION_TYPES.SET_IS_OPEN:
      return {
         ...state,
         isOpen: payload
        };
      case MODAL_ACTION_TYPES.SET_MODAL_TYPE:
      return {
         ...state,
         modalType: payload
        };
      default:
        return state;
    }
  };