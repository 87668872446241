import React, { useEffect, useState } from "react";
import mainLogo from "../../assets/main-logo.jpeg";
import './smallTopBar.styles.scss'
import Burger from "../../animations/burger/burger.animation";
import { Link } from "react-router-dom";

const SmallTopBar = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setIsSticky(currentScrollPos > prevScrollPos && currentScrollPos > 100);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);
    return (
        <div
            className={`small-top-bar-container small-top-bar-sticky ${isSticky ? "small-top-bar-hidden" : ""
                }`}
            id="home-container"
        >
            <div className="small-top-bar-logo-container">
                <Link className='small-top-bar-logo-element' to='/'>
                    <img className="small-top-bar-logo" src={mainLogo} alt="fireSpot" />
                </Link>
                <hr className="small-top-bar-hr" />
                <div className="small-top-bar-title-container">
                    <span className="small-top-bar-title">ORMA</span>
                    <span className="small-top-bar-subtitle">Servicios</span>
                </div>
            </div>
            <div className="small-top-bar-items-container">
                <Burger />
            </div>
        </div>
    );
};
export default SmallTopBar;
