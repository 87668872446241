// import { store } from "../../store/store";
export const API_URL = process.env.REACT_APP_API_URL;

export const registerUser = async (user) => {
    const {
        email,
        phoneNumber,
        dateOfBirth,
        placeOfBirth,
        street,
        parentName,
        password,
        reEnteredPassword,
        name,
        lastName,
        secondLastName,
        ext,
        int,
        hood,
        city,
        state,
        pc,
    } = user;

    const response = await fetch(`${API_URL}/accounts`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            phone: phoneNumber,
            dateOfBirth: dateOfBirth,
            placeOfBirth: placeOfBirth,
            street: street,
            extNumber: ext,
            insideNumber: int,
            hood: hood,
            city: city,
            state: state,
            pc: pc,
            parentFullName: parentName,
            password: password,
            passwordRepeat: reEnteredPassword,
            firstName: name,
            lastName: lastName,
            secondLastName: secondLastName,
        }),
    });
    const data = await response.json();
    return data;
};

export const login = async (user) => {
    const { email, password } = user;
    const response = await fetch(`${API_URL}/auth/login`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            user: email,
            password: password,
        }),
    });
    const data = await response.json();
    return data;
};

export const getAccountById = async (id) => {
    if (id) {
        const response = await fetch(`${API_URL}/accounts/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return data;
    } else {
        return "";
    }
};

export const changePassword = async (
    password,
    newPassword,
    reEnteredPassword
) => {
    const token = localStorage.getItem("token");
    if (!token) {
        throw new Error("Token not found in localStorage");
    }
    const response = await fetch(`${API_URL}/auth/change-password`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            password: password,
            newPassword: newPassword,
            passwordRepeat: reEnteredPassword,
        }),
    });
    const data = await response.json();
    return data;
};

export const checkForExistingUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
        const response = await fetch(`${API_URL}/accounts/mine`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data.data;
    } else {
        return "";
    }
};

export const updateUserInfo = async (userInfo) => {
    const {
        email,
        phoneNumber,
        dateOfBirth,
        placeOfBirth,
        street,
        parentName,
        firstName,
        lastName,
        secondLastName,
        extNumber,
        insideNumber,
        hood,
        city,
        state,
        pc,
    } = userInfo;
    const token = localStorage.getItem("token");
    if (!token) {
        throw new Error("Token not found in localStorage");
    }
    const response = await fetch(`${API_URL}/accounts`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            email: email,
            phone: phoneNumber,
            dateOfBirth: dateOfBirth,
            placeOfBirth: placeOfBirth,
            street: street,
            extNumber: extNumber,
            insideNumber: insideNumber,
            hood: hood,
            city: city,
            state: state,
            pc: pc,
            parentFullName: parentName,
            firstName: firstName,
            lastName: lastName,
            secondLastName: secondLastName,
        }),
    });
    const data = await response.json();
    return data;
};

export const listAccounts = async (search) => {
    const response = await fetch(
        `${API_URL}/accounts/search?search=${encodeURIComponent(search)}`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
    const data = await response.json();
    return data;
};
export const listAccountsByDate = async ({ startDate, endDate }) => {
    //pageIndex = 10 elements per page
    const realEndDate = endDate + "T23:59:59";
    const response = await fetch(
        `${API_URL}/accounts/search?startDate=${encodeURIComponent(
            startDate
        )}&&endDate=${encodeURIComponent(realEndDate)}`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
    const data = await response.json();
    return data;
};

export const loadFiles = async (file, id) => {
    //pageIndex = 10 elements per page
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("file", file);
    formData.append("originalName", file.name);
    const response = await fetch(`${API_URL}/files/upload/${id}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    });
    const data = await response.json();
    return data;
};

export const downloadFile = async (key) => {
    const token = localStorage.getItem("token");
    const response = await fetch(
        `${API_URL}/files/link-for-download?url=${key}`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    if (!response.ok) {
        throw new Error("Failed to download file");
    }
    const data = await response.json();
    return data;
};

export const sendContactMail = async (formData) => {
    const { firstName, lastName, email, message } = formData;
    const response = await fetch(`${API_URL}/contact`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: firstName,
            lastName: lastName,
            email: email,
            message: message,
        }),
    });
    return response;
};

export const forgotPass = async (formData) => {
    const { email } = formData;
    const response = await fetch(`${API_URL}/auth/forgot-password`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
        }),
    });
    return response;
};

export const passwordRecovery = async (password, reEnteredPassword, token) => {
    const response = await fetch(`${API_URL}/auth/reset-password`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            newPassword: password,
            passwordRepeat: reEnteredPassword,
            token: token,
        }),
    });
    return response;
};

export const activateAccount = async (email, token) => {
    const response = await fetch(`${API_URL}/accounts/activate`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            activationToken: token,
        }),
    });
    return response;
};
