import { combineReducers } from "redux";
import { modalReducer } from "./modal/modal.reducer";
import { ToggleButtonReducer } from "./toggleButton/toggleButton.reducer";
import { userReducer } from "./user/user.reducer";

export const rootReducer = combineReducers({
    modal: modalReducer,
    toggleButton: ToggleButtonReducer,
    user: userReducer,
})
