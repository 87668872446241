import React from "react";
import "./form-input.styles.scss";

const FormInput = ({ label, registerForm, ...otherProps }) => (
    <div className="group">
        <input
            className={`form-input ${registerForm ? "registerForm" : ""}`}
            {...otherProps}
        />
        {label && (
            <label
                className={`${otherProps.value.length ? "shrink" : ""
                    } form-input-label`}
            >
                {label}
            </label>
        )}
    </div>
);

export default FormInput;
